<template>

  <!-- RULES -->

  <a name="rules"></a>

  <div class="rl wd">

    <h2>Rules</h2>

    <div class="ds">
      Follow these easy steps and help us decide on the winners.
    </div>

    <div class="ls">
      <div class="lsi" v-for="(rule, index) in props.rules" :key="index">
        <div class="nm">{{ index + 1 }}</div>
        <div class="tx">{{ rule }}</div>
      </div>
    </div>

  </div>
  
</template>


<script setup>

const props = defineProps({
  rules: {
    type: Array,
    required: true
  }
})

</script>
 


<style scoped>
 

.rl{
    margin-top: 10rem;
    margin-bottom: 10rem;
}
.rl .ds{
    margin-bottom: 2rem;
}
.rl .ls{
    display: flex;
    flex-wrap: wrap;
}
.rl .ls .lsi{
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    width: 38%;
}
.rl .ls .lsi .nm{
    background-color: #ff8405;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 40px;
    color: white;
    margin-right: 10px;
    flex-shrink: 0;
}
.rl .ls .lsi .tx{
    padding-top: 2px;
}


@container pb (max-width: 600px) {

  .rl{}
  .rl .ds{}
  .rl .ls{}
  .rl .ls .lsi{
      width: 100%;
  }
  .rl .ls .lsi .nm{}
  .rl .ls .lsi .tx{}
}

</style>