<template>


    <div 
      v-if="category"
      :class="{'cti': true, 'disabled': category?.can_vote && !category.addon?.isVoted}"
      >
      <div class="br">{{ category.sub }}</div>
      <div class="nam">{{ category.name }}</div>
      <div class="ds">{{ category.description }}</div>
      <div
        :class="{'bt': true, 'disabled': category.addon.is_voted}"
        @click="showed = true"
        :data-category-id="category.id"
        >
        <div class="btn-text">Vote</div>
        <div class="cnt">{{ category.cnt }}</div>
      </div>
    </div>

    


    <GeneralSelectBoxModal
      v-if="showed"
      :cat="category.category_item"
      :input-placeholder="'Search...'"
      :title-modal="$t(`create_diary_add_brand_${category.category_item}_placeholder`)"
      :showed="showed"
      :faza="faza"
      :enable-custom="false"
      @choose="chooseItem"
      @prechoose="prechooseItem"
      @close="showed = false"
      />


  
</template>


<script setup>

const {$api, $popup} = useNuxtApp()

const props = defineProps({
  cupId: {
    type: Number,
    required: true
  },
  category: {
    type: Object,
    required: true
  }
})

const isLoading = ref(false)
const showed = ref(false)

const chooseItem = (data) => {
  console.log('props.category', props.category)
  console.log('props.cupId', props.cupId)
  console.log('data', data)
  if(props.category.type == 'brand' && data.item_brand.id){
    // vote(data.item_brand.id)
    vote({
      brand_id: data.item_brand.id,
    })
    showed.value = false
  }else if(props.category.type == 'product' && data.id){
    vote({
      product_id: data.id,
    })
    showed.value = false
  }
  
}

const prechooseItem = (data) => {
  console.log(data)
}

const vote = (data) => {

  isLoading.value = true
  $api.postAwardVote(props.cupId, props.category.id, data)
    .then(response => {
      isLoading.value = false
      $popup.success('Voted');
      props.category.addon.is_voted = true
    })
    .catch(error => {
      isLoading.value = false
      $popup.error('error');
    });
}


</script>
 


<style scoped>
 
 
.cti{
  border-radius: 10px;
  background-color: var(--un-background-color-gray);
  width: calc(100% / 4 - 2rem);
  padding: 1.6rem;
}
.cti .br{
    color: #a1a1a1;
    margin-bottom: 5px;
}
.cti .nam{
  font-weight: bold;
  min-height: 60px;
  font-size: 1.1rem;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.cti .ds{
  margin-top: 1rem;
  display: none;
}

.cti .bt{
  border-radius: 5px;
  padding: 10px;
  color: var(--un-text-color);
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: var(--un-firm);
  width: 100%;
  margin-top: auto;
  color: white;  
}
.cti .bt:hover{
  background-color: var(--un-firm);
  color: white;
  opacity: 0.9;
}
.cti .bt .btn-text{
}
.cti .bt .cnt{
    margin-left: auto;
    display: none;
}
.cti .bt.disabled{
  opacity: 0.4;
  pointer-events: none;
}


@container pc (max-width: 600px) {
 
  .cti{
    width: calc(50% - 2rem);
    min-width: 240px;
  }
  .cti .br{}
  .cti .nam{}
  .cti .ds{}
  .cti .bt{}
  .cti .bt .btn{}
  .cti .bt .cnt{}
}

</style>