<template>

  <!-- ABOUT -->

  <a name="about"></a>

  <div class="ab wd" v-html="props.description">    

 
  </div>
 
  
</template>


<script setup>

const props = defineProps({
  description: {
    type: String,
    required: true
  }
})

</script>
 


<style scoped>
  


.ab {
  font-size: 1.2rem;
}
.ab p{
  margin-bottom: 1rem;
}
@container pc (max-width: 600px) {
 
.ab {

}

.ab .about1 {

}

.ab .about2 {

}
}
 
</style>