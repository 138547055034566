<template>


  <!-- CATEGORY -->

  <a name="categories"></a>

  <div class="cg wd" v-if="props.votes">

    <h2>Votes</h2>

    <!-- <div class="ds">
      Please vote only one time in each nomination.
    </div> -->


    <div class="ct">
      <AwardVoteRow         
        v-for="vote in props.votes" :key="vote.category_id"
        :category="voteCategory(vote.id)"
        :cup-id="props.cupId"
        :votes="vote.items"
        :show-winners="props.showWinners"
        />
    </div>

  </div>

  
</template>


<script setup>


const props = defineProps({
  cupId: {
    type: Number,
    required: true
  },
  categories: {
    type: Array,
    required: true
  },
  votes: {
    type: Array,
    required: true
  },
  showWinners: {
    type: Boolean,
    required: true
  }
})


 
const voteCategory = (categoryId) => {
  return props.categories.find(category => category.id === categoryId)
}







</script>
 


<style scoped>
 

.cg{
}
.cg .ds{
    margin-bottom: 2rem;
}
.cg .ct{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
 

@container pc (max-width: 600px) {

  .cg{}
  .cg .ds{}
  .cg .nam{

  }
  .cg .ct{
    flex-direction: column;
  }
  .cg .ct::-webkit-scrollbar {
    display: none;
  } 
}

</style>