<template>
  <div class="aw" v-if="awardData">

    <AwardHero 
      :caption="awardData.caption"
      :year="awardData.year"
      />

    <AwardAbout 
      :description="awardData.description"
      />

    <AwardRules 
      :rules="awardData.rules"
      />
 
    <AwardVotes 
      v-if="awardData.items_vote?.length"
      :show-winners="awardData.is_finished"
      :cup-id="awardData.id"
      :categories="awardData.items_category"
      :votes="awardData.items_vote"
      />

    <AwardCategories 
      v-if="awardData.can_vote"
      :cup-id="awardData.id"
      :categories="awardData.items_category"
      />

  </div>
</template>

<script setup>

const route = useRoute();
const {$api, $adv, $tagsUtil, $ga, $patcher} = useNuxtApp()
const year = ref(route.params['year'])

const loadData = async function() {   
 
 const response = await $api.getAwardSection(year.value);  

 return response;  
}


const loadMore = async function() {    
 start.value += limit.value;
}


const { pending: isLoading,  data: awardData } = await useLazyAsyncData('awardData', async () => await loadData())



</script>

<style scoped>


.aw{

}

.aw:deep(.wd){
    max-width: 1020px;
    margin: 4rem auto;
    padding: 0rem 1rem;
}
.aw .tp{
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.aw .tp .lg{
    max-width: 200px;
    margin-right: auto;
}
.aw .tp .mn{}
.aw .tp .mn .mni{
    text-decoration: underline;
    margin: 0 8px;
}
.aw .tp .mn .mnib{
    background-color: #5aa62a;
    color: white;
    border-radius: 5px;
    padding: 7px 20px;
    margin-left: 10px;
}


.aw:deep(h2){
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

@container pc (max-width: 600px) {

}

</style>